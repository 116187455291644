.photosTitle {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #ff2d47;
}

.scans-container {
  width: 60%;
  margin: 0 auto;
  padding: 20px;
  font-family: "Eina03-Regular", sans-serif;
}

.img-title {
  text-align: center;
}

.img-component {
  width: 80px !important;
  object-fit: contain !important;
}

.scans-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  margin: 40px;
  padding: 2rem;
}

.scans-row-wrapper {
  display: flex;
  column-gap: 5rem;
  justify-content: center;
}

.scans-download-btn {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
}