.buttonContainer {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.ctaButton {
  background-color: #ff2c46;
  padding: 15px 24px;
  margin: 8px;
  color: white;
  border-radius: 50px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1.25px;
  line-height: 19px;
  box-shadow: 0 4px 10px 0 rgba(135, 167, 171, 0.5);
  display: inline-block;
  border: unset;
  width: 230px;
  text-decoration: none !important;
  cursor: pointer;
}

.ctaButton:hover {
  background-color: rgba(255, 0, 0, 0.3) !important;
}

.ctaButton2 {
  background-color: white !important;
  padding: 15px 24px;
  margin: 8px;
  color: #ff2c46 !important;
  border-radius: 50px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1.25px;
  line-height: 19px;
  display: inline-block;
  width: 230px;
  border-color: #ff2c46;
  text-decoration: none !important;
  cursor: pointer;
}

.loading-container > .loading {
  border: 2px solid #f3f3f3;
  border-top: 3px solid #ff2d47;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

.error-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
  color: #ff2d47;
}

.loading-container {
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
