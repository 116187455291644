.step3-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.step3-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
}

.step3-item:hover {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
}

.step3-thumbnail {
  margin-bottom: 10px;
}

.step3-label {
  text-align: center;
}
.photosTitle {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #ff2d47;
}

.photosContainer {
  width: 60%;
  margin: 0 auto;
  padding: 20px;
  font-family: "Eina03-Regular", sans-serif;
}

.img-title {
  text-align: center;
}

.img-component {
  width: 80px !important;
  object-fit: contain !important;
}

.photos-download-btn {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
}